<template>
    <div id="cont-manager" :key="langKey">
        <div id="entity">
            <div id="entity-cont">
                <label for="entity-choice">{{texts.entity}}</label>
                <div id="loader-entity" class="lds-ring" v-if="loadingEntity"><div></div><div></div><div></div><div></div></div>
                <select v-if="!loadingEntity" name="entity-choice" id="entity-choice" v-model="entitySelected">
                    <option disabled value="">{{texts.select}}</option>
                    <option v-for="(entity, index) in entities" :value="index">{{entity.description}}</option>
                </select>
                <button id="btn-vpn-entity" :disabled="!entitySelected" @click="showVPNEntity">VPNs</button>
            </div>
            <router-link to="/" id="close-manager"><fa :icon="['fas', 'times']"/></router-link>
        </div>
        <div id="wrapper-users">
            <div id="cont-users">
                <div id="cont-btns-users">
                    <div id="wrapper-btns-users">
                        <div>
                            <button id="btn-new" @click="showNewAccount" :disabled="entitySelected == ''">{{texts.new.title}}</button>
                            <button id="btn-edit-cont" @click="showEditAccount"  :disabled="userSelectedKey">{{texts.edit.edit}} <fa icon="sort-down" />
                                <section id="dropdown-content-edit" v-if="!userSelectedKey">
                                    <div class="btns-edit" :title="texts.edit.delete.title" @click="showDeleteAccount">{{texts.edit.delete.text}}</div>
                                    <div class="btns-edit" :disabled="isSecondary" :title="texts.edit.status.title" @click="showStatusAccount">{{texts.edit.status.text}}</div>
                                    <button class="btns-edit" id="btn-move"  :disabled="isSecondary" @click="showMoveAccount">{{texts.edit.move}}</button>
                                </section>
                            </button>
                        </div>
                        <div>
                            <button id="btn-envelope" :disabled="userSelectedKey" :title="texts.mailpass" @click="mailPassword">
                                <svg id="envelope" version="1.1" baseProfile="full" width="30" height="30" viewBox="0 -40 600 600" xmlns="http://www.w3.org/2000/svg"><g><path d="M584.1,230c-26,20.2-60.3,45.9-178.6,131.8c-23.5,17.2-65.6,55.5-107,55.2c-41.2,0.2-82.4-37.3-106.9-55.2
                                        C73.3,276,38.8,250.3,12.8,230.1c-4.6-3.6-11.2-0.3-11.2,5.4v237c0,30.7,24.9,55.7,55.7,55.7h482.4c30.7,0,55.7-24.9,55.7-55.7
                                        V235.5C595.4,229.8,588.6,226.4,584.1,230z"/>
                                    <circle cx="434.6" cy="187.6" r="21"/>
                                    <path d="M539.6,83H57.3c-30.7,0-55.7,24.9-55.7,55.7v22c0,8.6,3.9,16.6,10.7,21.9c35.5,27.7,47.2,37.6,201.1,149.2
                                        c19.5,14.1,58.2,48.5,85.1,48c26.9,0.5,65.6-33.9,85.1-48c153.9-111.7,165.6-121.4,201.1-149.2c6.7-5.2,10.7-13.3,10.7-21.9v-22
                                        C595.4,107.9,570.5,83,539.6,83z M386.3,267.6c-33.2,0-61.6-20.2-73.7-48.9l-10.8-0.2l-10.1,0.2h-10.6L262.7,207l-11.4,11.7h-14
                                        l-13.6-15.1l-21.5,15.1h-13.1l-11.4-8.1l-13.1,8.1h-21.2L108,189.1l35.5-29.6h168c11.4-30.4,40.6-51.9,74.9-51.9
                                        c44.2,0,80,35.8,80,80S430.5,267.6,386.3,267.6z"/></g>
                                </svg>
                            </button>
                            <button id="help-users" @click="showHelpAccount" >{{texts.help}} </button>
                        </div>
                    </div>
                    <div id="filter">
                        <label for="filter">{{texts.filter}} :</label>
                        <input type="text" name="filter" id="filter" v-model="filterBy" :placeholder="texts.new.placeholder" :disabled="entitySelected == ''">
                    </div>
                </div>


                <div id="cont-table" :key="newUserKey" >
                    <ellipsis v-if="loadingUsers" />
                    <table v-if="!loadingUsers">
                        <thead>
                            <tr>
                                <th>{{texts.lastname}}</th>
                                <th>{{texts.firstname}}</th>
                                <th>{{texts.login}}</th>
                                <th>{{texts.member.text}}</th>
                                <th>{{texts.state.text}}</th>
                            </tr>
                        </thead>
                        <tbody id="table-body">
                            <tr v-for="user in usersList" @click="getUser(user.login)" :id="user.login">
                                <td>{{user.familyName}}</td>
                                <td>{{user.givenName}}</td>
                                <td>{{user.login}}</td>
                                <td>
                                    <p v-if="user.primaryAffectation.id == entitySelected">{{texts.member.member}}</p>
                                    <p v-else >{{texts.member.guest}}</p>
                                </td>
                                <td><p v-if="user.expired">{{texts.state.inactive}}</p>
                                    <p v-if="!user.expired">{{texts.state.active}}</p>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>

            <ellipsis v-if="loadingUser" />

            <div id="cont-user" v-if="!loadingUser">
                <div class="tiles-user">{{texts.user}}</div>
                <div><p class="tiles-user">{{texts.lastname}}</p> <input class="namefield" pattern="^[a-zA-Z][a-zA-Z_ -]*[a-z]$" type="text" name="familyNameInput" id="familyNameInput" v-model="familyNameInput" @keyup="chgesInfos" @keydown="chgeFamilyKey = true" v-if="!userSelectedKey" :disabled="isSecondary"><div v-if="familyNameInputVisible"></div></div>
                <div><p class="tiles-user">{{texts.firstname}}</p> <input class="namefield" pattern="^[a-zA-Z][a-zA-Z_ -]*[a-z]$" type="text" name="givenNameInput" id="givenNameInput" v-model="givenNameInput" @keyup="chgesInfos" @keydown="chgeNameKey = true" v-if="!userSelectedKey" :disabled="isSecondary"><div v-if="givenNameInputVisible"></div></div>
                <div><p class="tiles-user">{{texts.login}}</p> <input type="text" name="loginInput" id="loginInput" v-model="loginInput" v-if="!userSelectedKey" disabled></div>
                <div><p class="tiles-user">{{texts.mail}}</p> <input type="text" name="contactInput" id="contactInput" v-model="contactInput" @keyup="chgesInfos" @keydown="chgeContactKey = true" v-if="!userSelectedKey" :disabled="isSecondary"><div v-if="contactInputVisible"></div></div>
                <div><p class="tiles-user">{{texts.mailplm}}</p> <input type="text" name="plmailInput" id="plmailInput" v-model="plmailInput" v-if="!userSelectedKey" disabled></div>
                <div><p class="tiles-user">{{texts.shell}}</p> <input type="text" name="shellInput" id="shellInput" v-model="shellInput" v-if="!userSelectedKey" disabled></div>
                <div><p class="tiles-user">{{texts.arrival}}</p> <input type="date" name="beginsAtInput" id="beginsAtInput" :value="dateToYYYYMMDD(beginsAtInput)" @input="beginsAtInputKey = $event.target.valueAsDate" @change="chgesInfos" v-if="!userSelectedKey"></div>
                <div><p class="tiles-user">{{texts.departure}}</p> <input type="date" name="endsAtInput" id="endsAtInput" :value="dateToYYYYMMDD(endsAtInput)" @input="endsAtInputKey = $event.target.valueAsDate" @change="chgesInfos" v-if="!userSelectedKey"></div>
                <div><p class="tiles-user">{{texts.comments}}</p> <input type="text" name="commentInput" id="commentInput" v-model="commentInput" @keyup="chgesInfos" @keydown="chgeCommentKey = true" v-if="!userSelectedKey"><div v-if="commentInputVisible"></div></div>
                <div><p class="tiles-user">{{texts.other}}</p><section id="other-affectations"><p v-for="affectation in otherAffectations">{{affectation}}</p></section></div>
                <div v-if="!userSelectedKey && !isSecondary" id="cont-user-vpns">
                    <p class="tiles-user">{{texts.vpns}} :</p>
                    <button class="btn-edit-user-vpn" @click="showVPNUser"><fa :icon="['fas', 'edit']"/></button>
                    <section id="user-vpns"><p v-for="vpn in userVpns">{{vpn.ip}} | {{vpn.description}}</p></section>
                </div>

                <button id="btn-user-update" :disabled="chgesInfosKey" @click="userUpdate(userSelected.login)">{{texts.btnuser}}</button>
            </div>
        </div>
    </div>

    <HelpAccount ref="help" />
    <NewPerson :entity="entitySelected" ref="newPerson" />
    <DeleteAccount :entity="entitySelected" :user="userSelected" ref="deleteAccount" />
    <ChgeStatusAccount :entity="entitySelected" :user="userSelected" :member="member" ref="statusAccount" />
    <MoveAccount :user="userSelected" :entities="entitiesNames" ref="moveAccount" />
    <VPNEntity v-if="entitySelected" :entity="entitySelected" ref="vpnEntity" />
    <VPNUser v-if="userSelected" :user="userSelected" ref="vpnUser" />
    <confirm-dialogue ref="confirmDialogue" />

</template>

<script>
import HelpAccount from '@/components/HelpAccount.vue';
import NewPerson from '@/components/NewPerson.vue';
import DeleteAccount from '@/components/DeleteAccount.vue';
import ChgeStatusAccount from '@/components/ChangeStatusAccount.vue';
import MoveAccount from '@/components/MoveAccount.vue';
import VPNEntity from '@/components/VPNEntity.vue';
import VPNUser from '@/components/VPNUser.vue';
import ConfirmDialogue from '@/components/ConfirmDialogue.vue';
import Ellipsis from '@/components/Ellipsis.vue';

export default{
    components: {
        HelpAccount,
        NewPerson,
        DeleteAccount,
        ChgeStatusAccount,
        MoveAccount,
        VPNEntity,
        VPNUser,
        ConfirmDialogue,
        Ellipsis,
    },
    data(){
        return{
            commonTexts: {},
            texts:{
                "entity": null,
                "new": {
                    "title": null,
                    "placeholder": null,
                },
                "edit": {
                    "edit": null,
                    "delete": {
                        "title": null,
                        "text": null
                    },
                    "move": null,
                    "status": {
                        "title": null,
                        "text": null
                    },
                },
                "help": null,
                "filter": null,
                "lastname": null,
                "firstname": null,
                "login": null,
                "member": {
                    "text": null,
                    "member": null,
                    "guest": null
                },
                "user": null,
                "state": {
                    "text": null,
                    "active": null,
                    "inactive": null
                },
                "mail": null,
                "mailpass": null,
                "mailplm": null,
                "shell": null,
                "arrival": null,
                "departure": null,
                "comments": null,
                "other": null,
                "vpns": null,
                "select": null,
                "btnuser": null,
            },
            langstore: null,
            loadingEntity: false,
            loadingUsers: false,
            loadingUser: false,
            entities: {},
            entitiesNames: {},
            entitySelected: "",
            users: [],
            userSelected: {},
            otherAffectations: [],
            userVpns: [],
            userSelectedKey: true,
            newPersonKey: null,
            filterBy: "",
            familyNameInput: "",
            familyNameInputVisible: false,
            chgeFamilyKey: false,
            givenNameInput: "",
            givenNameInputVisible: false,
            chgeNameKey: false,
            loginInput: "",
            chgeLoginKey: false,
            contactInput: "",
            contactInputVisible: false,
            chgeContactKey: false,
            plmailInput: "",
            shellInput: "",
            beginsAtInput: "",
            beginsAtInputKey: "",
            chgeBeginsKey: false,
            endsAtInput: "",
            endsAtInputKey: "",
            chgeEndsKey: false,
            commentInput: "",
            commentInputVisible: false,
            chgeCommentKey: false,
            chgesInfosKey: true,
        };
    },
    mounted(){
        // requête pour textes selon langue
        this.getTexts();


        //requête GET profile pour récupére les unités gérées
        this.loadingEntity = true;
        fetch(this.$baseUrl("/api/profile"))
        .then(response => response.json())
        .then(data => {
          this.entities = data.entities;
          this.loadingEntity = false;
        })
        .catch((error) => console.log(error, response));

        //requête GET entities pour récupére nom de toutes les unités
        fetch(this.$baseUrl('/api/entities'))
        .then(response => response.json())
        .then(data => {
          this.entitiesNames = data;
        })
        .catch((error) => console.log(error));

    },
    computed:{
        langKey(){
            this.langstore = this.$store.state.langKey;
        },

        newUserSelected(){
            return this.userSelected;
        },

        newUserKey(){
            this.newPersonKey = this.$store.state.newPersonKey;
        },

        member() {
           if ((this.userSelected.primaryAffectation !== undefined) &&
           (this.entitySelected !== undefined)) {
            return this.userSelected.primaryAffectation.id != this.entitySelected
           }
           else {
            return false;
           }
        },

        isSecondary() {
            return (this.userSelected !== undefined) && (this.userSelected.primaryAffectation.id != this.entitySelected);
        },

        usersList() {
          var self = this;
          var users = self.users;
          if (users.data.length > 0 && self.filterBy && self.filterBy.length > 0) {
              return users.data.filter(function(item) {
                return item.familyName.toLowerCase().indexOf(self.filterBy.toLowerCase()) > -1 ||
                 item.givenName.toLowerCase().indexOf(self.filterBy.toLowerCase()) > -1 ||
                 item.login.toLowerCase().indexOf(self.filterBy.toLowerCase()) > -1 ;
              });
          } else {
            return users.data;
          }
        },
    },
    watch: {
        // chaque fois que langStore change, la requête pour la langue des textes s'exécute.
        langstore(newLangstore, oldLangstore) {
            if (newLangstore > oldLangstore) {
                this.getTexts()
            }
        },

        // chaque fois que entitySelected change, la requête pour les users d'une unité s'exécute.
        entitySelected(newEntitySelected, oldEntitySelected) {
            if (newEntitySelected != oldEntitySelected) {
                this.getUsers();
                // efface le champ filtre
            }
        },

        // chaque fois qu'une nouvelle personne est ajouté on refait la requête pour rafraichir les utilisateurs
        newPersonKey(newNewPersonKey, oldNewPersonKey) {
            if (newNewPersonKey != oldNewPersonKey) {
                this.userSelectedKey = true;
                this.userSelected = {};
                this.getUsers();
            }
        },

        // je remet au format YYYY/MM/DD la date pour qu'elle puisse repasser dans la methode dateToYYYYMMDD (car l'input me renvoie un format: Frid Nov 26 ... )
        beginsAtInputKey(newBeginsAtInputKey, oldBeginsAtInputKey) {
            if (newBeginsAtInputKey != oldBeginsAtInputKey) {
                try {
                    let dateIso = this.beginsAtInputKey.toISOString().substring(0, 10);
                    let dateParts = dateIso.split("-");
                    this.beginsAtInput = dateParts[2]+"/"+dateParts[1]+"/"+dateParts[0];
                    this.chgeBeginsKey = true;
                } catch (error) {
                    console.log(error);
                }
            }
        },

        endsAtInputKey(newEndsAtInputKey, oldEndsAtInputKey) {
            if (newEndsAtInputKey != oldEndsAtInputKey) {
                try {
                    let dateIso = this.endsAtInputKey.toISOString().substring(0, 10);
                    let dateParts = dateIso.split("-");
                    this.endsAtInput = dateParts[2]+"/"+dateParts[1]+"/"+dateParts[0];
                    this.chgeEndsKey = true;
                } catch (error) {
                    console.log(error);
                }

            }
        },

    },
    methods:{
        showHelpAccount(){
            this.$refs.help.open();
        },

        showNewAccount(){
            this.$refs.newPerson.open();
        },

        showEditAccount(){
            document.getElementById("dropdown-content-edit").classList.toggle("isOpen");
        },

        showDeleteAccount(){
            this.$refs.deleteAccount.open();
        },

        showStatusAccount(){
            this.$refs.statusAccount.open();
        },

        showMoveAccount(){
            this.$refs.moveAccount.open();
        },

        showVPNEntity(){
            this.$refs.vpnEntity.open();
        },

        showVPNUser(){
            this.$refs.vpnUser.open();
        },

        chgesInfos(){
            if (this.givenNameInput.match(/^[a-zA-Z_ -]+$/) && this.familyNameInput.match(/^[a-zA-Z_ -]+$/) && this.mailIsValid(this.contactInput))
              this.chgesInfosKey = false;
            else
              this.chgesInfosKey = true;
        },

        //refait la requête pour les textes au changement de langue, les otherMails et shell à la modification de ceux-ci
        getTexts() {
            this.texts = this.$I18n()[this.$store.state.lang].manageaccounts;
            this.commonTexts = this.$I18n()[this.$store.state.lang].common;

        },

        //refait la requête pour les infos des users d'une unité
        getUsers() {

            this.otherAffectations = [];
            this.loadingUsers = true;
            this.loadingEntity = true;
            // enlève un background vert à l'ancienne ligne selectionné
            let selected = document.querySelector(".selected");

            if(selected !== null && selected !== ""){
                selected.classList.remove("selected");
            }

            // rend disable le btn pour modifier le compte utilisateur
            this.userSelectedKey = true;

            // vide le champ utilisateurs
            this.users = [];
            // vide le champ utilisateur
            this.userSelected = {};

            // disabled le le btn pour appliquer les changement
            this.chgesInfosKey = true;

            //requête GET users pour récupére les utilisateurs d'une entityé gérée
            fetch(this.$baseUrl(`/api/users?entity=${this.entitySelected}`))
            .then(response => response.json())
            .then(data => {
              this.users = data;
              this.loadingUsers = false;
              this.loadingEntity = false;
            })
            .catch((error) => console.log(error));
        },

        // je met une date au format YYYYMMDD pour qu'elle soit utilisable par les inputs type=date
        dateToYYYYMMDD(d) {
            let dateString = d;
            let dateParts = dateString.split("/");
            return dateParts[2]+"-"+dateParts[1]+"-"+dateParts[0];
        },

        mailIsValid(mail){
          return !!mail && (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail))
        },

        //
        getUser(login){
            this.loadingUser = true;
            if (document.getElementById("dropdown-content-edit")) {

                document.getElementById("dropdown-content-edit").classList.remove("isOpen");
            }

            this.otherAffectations = [];
            this.userVpns = [];

            // rajoute un background vert à la ligne selectionné
            let selected = document.querySelector(".selected");

            if(selected !== null && selected !== ""){
                selected.classList.remove("selected");
            }

            // disabled le le btn pour appliquer les changement
            this.chgesInfosKey = true;

            document.getElementById(login).classList.add("selected");

            //requête GET users+login pour récupére infos d'un utilisateur
            fetch(this.$baseUrl(`/api/users/${login}`))
            .then(response => response.json())
            .then(data => {
                this.userSelected = data;
                this.userSelected.affectations.forEach(affectation =>{
                    if (affectation != this.entitySelected) {
                        let entity = this.entitiesNames.find(({id}) => id == affectation);
                        this.otherAffectations.push(entity.name);
                    }
                })
              // j'affiche les infos du user dans les inputs
              this.familyNameInput = this.userSelected.familyName;
              this.givenNameInput = this.userSelected.givenName;
              this.loginInput = this.userSelected.login;
              this.contactInput = this.userSelected.contactEmail;
              this.plmailInput = this.userSelected.plmEmail;
              this.shellInput = this.userSelected.shell;
              if (this.userSelected.primaryAffectation != undefined && this.userSelected.primaryAffectation.id == this.entitySelected) {
                this.beginsAtInput = this.userSelected.primaryAffectation.beginsAt;
              }else if(this.userSelected.primaryAffectation != undefined && this.userSelected.primaryAffectation.id != this.entitySelected){
                this.userSelected.secondaryAffectations.forEach(affect =>{
                  if(affect.id == this.entitySelected){
                    this.beginsAtInput = affect.beginsAt;
                  }
                })
              }
              if (this.userSelected.primaryAffectation != undefined && this.userSelected.primaryAffectation.id == this.entitySelected) {
                this.endsAtInput = this.userSelected.primaryAffectation.endsAt;
              }else if(this.userSelected.primaryAffectation != undefined && this.userSelected.primaryAffectation.id != this.entitySelected){
                this.userSelected.secondaryAffectations.forEach(affect =>{
                  if(affect.id == this.entitySelected){
                    this.endsAtInput = affect.endsAt;
                  }
                })
              }
              if (this.userSelected.primaryAffectation != undefined && this.userSelected.primaryAffectation.id == this.entitySelected) {
                this.commentInput = this.userSelected.primaryAffectation.comment;
              }else if(this.userSelected.primaryAffectation != undefined && this.userSelected.primaryAffectation.id != this.entitySelected){
                this.userSelected.secondaryAffectations.forEach(affect =>{
                  if(affect.id == this.entitySelected){
                    this.commentInput = affect.comment;
                  }
                })
              }
              this.userVpns = this.userSelected.vpn;
              this.userSelectedKey = false;
              this.loadingUser = false;
            })
            .catch((error) => console.log(error));
        },

        userUpdate(login){
            let params = {}
            params["entity"] = this.entitySelected;
            if(this.chgeFamilyKey){
                params["familyName"] = this.familyNameInput;
            }
            if (this.chgeNameKey) {
                params["givenName"] = this.givenNameInput;
            }
            if (this.chgeContactKey) {
                params["contactEmail"] = this.contactInput;
            }
            if (this.chgeBeginsKey) {
                params["beginsAt"] = this.beginsAtInput;
            }
            if (this.chgeEndsKey) {
                params["endsAt"] = this.endsAtInput;
            }
            if (this.chgeCommentKey) {
                params["comment"] = this.commentInput;
            }

            var body = JSON.stringify(params);

            fetch(this.$baseUrl(`/api/users/${login}`),
            {method: 'PATCH', body: body})
            .then(response => {
                if (!response.ok) {
                    throw new Error(`HTTP error, status = ${response.status}, message = ${response.statusText}`);
                }
                return response;
            })
            .then((response) => {
                this.$refs.confirmDialogue.show({
                  title: this.commonTexts.generic,
                   message: this.commonTexts.genericAnswer
                });
                this.$store.commit('SETNEWPERSONKEY');
            })
            .catch((error) => {
                this.$refs.confirmDialogue.show({
                title: this.commonTexts.error,
                message: `${this.commonTexts.genericError} \n ${error}`
                });
            });
        },

        mailPassword(){
            var body = JSON.stringify({uid: this.userSelected.login, mail: this.userSelected.contactEmail});
            fetch(this.$baseUrl('/api/password'),
            {method: 'POST', body: body})
            .then(response => {
                if (!response.ok) {
                    throw new Error(`HTTP error, status = ${response.status}, message = ${response.statusText}`);
                }
                return response;
            })
            .then(response => {
                this.$refs.confirmDialogue.show({
                    title: this.commonTexts.generic,
                    message: this.commonTexts.genericAnswer
                });
            })
            .catch((error) => {
                this.$refs.confirmDialogue.show({
                    title: this.commonTexts.error,
                    message: `${this.commonTexts.genericError} \n ${error}`
                });
            });
        },
    },
}

</script>

<style lang="scss" scoped>
@import "../style/style.module.scss";
@import "../style/typo.scss";
@import "../style/manageaccounts.scss";

</style>
